body {
  @apply bg-gray-100 dark:bg-gray-900 cursor-none w-screen h-screen;
}

input {
  @apply px-4 py-2 border border-gray-200 rounded-md;
}

main {
  > *,
  blockquote > * {
    @apply mb-6;
  }

  .highlight-line {
    @apply pl-3;
  }

  > :last-child,
  blockquote > :last-child {
    @apply mb-0;
  }

  li {
    @apply mb-3;
  }

  li:last-child {
    @apply mb-0;
  }

  ul {
    list-style-type: disc;
    padding-left: theme("padding.6");
  }

  ol {
    list-style-type: decimal;
    padding-left: theme("padding.6");
  }
}

.cursor {
  //mix-blend-mode: normal;
  @apply h-2
         w-2
         -mt-1 
         -ml-1
         bg-slate-800/40
         border-4 
         border-slate-800/40 
         rounded-sm
         z-50 
         backdrop-blur
         pointer-events-none 
         absolute
         transition
         duration-300 
         ease-in-out
         opacity-100
         rotate-45;
// -ml-2 
  //box-shadow: 0 0 40px 20px rgba(241, 246, 246, 0.2);
}
//  Need TW class to transition all these but not movement
//  transition-opacity
//  transition-colors
//  transition-transform

// Class for when cursor is on a hover-block
// Scale and make opacity less transparent so that it looks more like a 
// spotlight - should be white instead of gray or an arbitrary color like blue/purple

.like {
  @apply rounded-full 
         right-8 
         bottom-60 
         bg-gray-900 
         h-24 
         w-24 
         font-bold 
         text-gray-100 
         transition-opacity
         duration-500 
         ease-in-out;
}

.animate-up {
  @apply bg-green-500 transition-colors duration-3000 ease-linear #{!important};
}

.animate-down {
  @apply bg-purple-500 transition-colors duration-3000 ease-linear #{!important};
}

.like > .text {
  @apply inline;
}

.like:hover > .text {
  @apply hidden;
}

.like > .count {
  @apply hidden;
}

.like:hover > .count {
  @apply inline;
}

.link {
  @apply blur-xl;
}

.click {
  @apply transform scale-75;
}

.moving {
  @apply transform scale-150;
}

.idle {
  @apply opacity-10 transform scale-100;
}

.focus {
  @apply transform scale-200 opacity-100 blur-md bg-white border-white duration-100;
}

.spotlight {
  @apply scale-200;
}

// .prose > a,p,span,code,pre,h1,h2,h3,h4,h5,h6 

.prose-focus {
  @apply transform rotate-0 h-5 w-1 rounded-full px-0 backdrop-blur-sm bg-gray-900/10 border-gray-900/10;
}

.nav-item {
  @apply px-8 
        py-4 
        block 
        transition-all
        border-2 
        rounded-md
        border-transparent
        dark:hover:border-gray-800 
      hover:border-gray-200
        focus:shadow-none 
      focus:border-gray-100
        font-bold 
        hover:shadow-sm 
        backdrop-filter
        hover:backdrop-blur-3xl 
        hover:backdrop-brightness-125 
        hover:dark:backdrop-brightness-90;
}

.hover-area {
  @apply px-8 
        py-12 
        fixed 
        hover:left-0 
        top-24 
        z-50
  transition-transform hover:translate-x-2;
}

.toggle {
  @apply py-9 px-4 rounded-r-lg bg-gray-800 
    text-gray-100 dark:text-gray-800 
    dark:bg-gray-100 font-bold
    shadow-sm hover:shadow-lg transition-transform 
    -translate-x-18 hover:-translate-x-10;
}

.gradient-tracking {
  @apply overflow-hidden relative;
}

.gradient-tracking:before {
  @apply absolute;
  mix-blend-mode: hard-light;
  --size: 100;
  content: "";
  left: var(--x);
  top: var(--y);
  width: var(--size);
  height: var(--size);
  background: radial-gradient(
    circle closest-side,
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0)
  );
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gradient-tracking:hover:before {
  --size: 300px;
}

@layer utilities {
  .gradient-tracking-900:hover:before {
    --size: 300px;
  }

  .gradient-tracking-900 {
    @apply overflow-hidden relative;
  }

  .gradient-tracking-900:before {
    @apply absolute;
    mix-blend-mode: soft-light;
    --size: 40;
    content: "";
    left: var(--x);
    top: var(--y);
    width: var(--size);
    height: var(--size);
    background: radial-gradient(
      circle closest-side,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0)
    );
    transform: translate(-50%, -50%);
    transition: width 0.2s ease, height 0.2s ease;
  }
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.fun-underline {
  background: linear-gradient(to left, #1fd2e2, #9be6ba 100%);
  background-position: 0 100%;
  background-size: 100% 24px;
  background-repeat: repeat-x;
  mask-image: inherit;
}

.underline--bacon {
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/78779/bacon.svg");
  background-position: 0 1.06em;
  background-size: 28px 9px;
  color: #9e4446;
}


.token.tag {
  @apply bg-transparent hover:bg-gray-300/30 hover:rounded-md p-1;
}

// .language-html > * {
//   @apply inline-block {!important};
// }

.collection {
  // @apply relative;
}

.collection a, .post-link {
  @apply hover:text-orange-600 
          hover:underline 
          underline-offset-2;
  // absolute
  // rotate-0 
  // hover:rotate-2;
}

.prose > hr {
  @apply border-gray-300 dark:border-gray-700;
}

.hover-block {
  @apply hover:ring-4 hover:ring-slate-500/5 hover:shadow-2xl hover:ring-offset-slate-500/10 hover:ring-offset-4;
}

// TODO - Make pre with hover-block type things
// TODO - Make hover-block more nuanced by html tag
//  - pre behaves slightly different than a than img